<template>
  <div>
    <modal
      :show.sync="show"
      class="modal-danger"
      headerClasses="justify-content-center"
    >
      <!--
      <div class="modal-profile" slot="header">
        <i class="tim-icons icon-alert-circle-exc"></i>
      </div>
      -->
      <h4 class="text-center">{{$t('APP.SURE')}}</h4>
      <p>{{$t('APP.ALL_DATA_RESET')}}</p>
      <template slot="footer">
        <base-button @click="onResetData" link type="neutral" class="text-uppercase">{{$t('APP.CONFIRM')}}</base-button>
        <base-button
          class="text-uppercase"
          @click="show = false"
          link
          type="neutral"
        >{{$t('APP.CANCEL')}}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from '@/components'
import eventBus from '@/plugins/event-bus';

export default {
    components: {
        Modal
    },
    name: "ResetAll",
    data() {
        return {
            show: false,
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    methods: {
        async onResetData() {
            await this.$store.dispatch('app/reset');
            await this.$store.dispatch('account/reset');
            //this.$store.dispatch('session/setAuth', false);
            this.$store.dispatch('resetData');
            //eventBus.emit('modal:onReset');

            this.show = false;
            //await this.$router.push({path: '/game'});
            document.location.reload();
        }
    },
    async created() {
        eventBus.on('modal:reset', async () => {
            this.show = true;
        });
    }
}
</script>

<style scoped>

</style>
