<template>
  <modal
    :show.sync="show"
    class="modal-black"
    headerClasses="justify-content-center"
  >
    <div class="pointer text-center" slot="header">
      <h4 class="title title-up font-weight-light text-center text-uppercase mb-2">{{$t('WALLET.SELL')}}
        {{(chipStore.chipsAmountSell * 1).toFixed(0) * 1}} CRYPTO {{$t('WALLET.CHIPS')}}</h4>
      <img src="@/game/assets/images/chips/gold-2.png" width="70px"/>
      <i class="tim-icons icon-double-right ml-3 mr-3"></i>
      <img src="@/game/assets/images/chips/sth-dark.png" width="72px"/>
    </div>


    <div v-show="chipsReal > 10" class="text-center">
      <p>{{$t('WALLET.YOU_GET')}} <span class="text-primary">{{(chipStore.chipsAmountSell - 10).toFixed(0)}}</span> STH
        <span class="small">({{$t('WALLET.FEE')}} 10 {{$t('WALLET.CHIPS')}})</span>
      </p>
    </div>

    <div v-show="chipsReal < 11" class="text-center">
      {{$t('WALLET.NO_CHIPS')}}
    </div>

    <div class="text-center pl-1 pr-4"
         v-if="chipsReal >= 100 && step === 1"
    >
      <p class="pb-2">
        {{$t('WALLET.SELL')}} <span class="text-primary">{{(chipStore.chipsAmountSell * 1).toFixed(0) * 1}}</span>
        CRYPTO {{$t('WALLET.CHIPS')}}
        <span @click="chipStore.chipsAmountSell = 100" class="float-left pointer">MIN</span>
        <span @click="chipStore.chipsAmountSell = chipsReal * 1" class="float-right pointer">MAX</span>
      </p>
      <slider
        :connect="true"
        :key="chipsReal"
        :step = "1"
        :range="{min: 100, max: chipsReal * 1}"
        :start="100"
        class="mb-2"
        type="secondary"
        v-model="chipStore.chipsAmountSell"></slider>
    </div>

    <div class="text-center" v-if="step === 2">
      {{$t('WALLET.EX_PROCESS_WAIT')}}...
    </div>

    <div class="text-center" v-if="step === 3">
      {{$t('WALLET.EX_COMPLETED')}}!
    </div>


    <template slot="footer">
      <base-button @click="sellChips" class="col-md-6 mt-2 text-uppercase" v-show="step === 1 && chipsReal > 10">{{$t('APP.CONFIRM')}}</base-button>
      <base-button
        @click.native="show = false"
        class="col-md-6 mt-2 text-uppercase"
        type="danger"
        v-show="step === 1 && chipsReal > 10"
      >{{$t('APP.CANCEL')}}
      </base-button>
    </template>

  </modal>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {Modal, Slider} from 'src/components';

export default {
    name: "SellChips",
    components: {
        Modal,
        Slider,
    },
    data() {
        return {
            show: false,
            amount: 100,
            step: 1,
            chipStore: {
                process: false,
                success: false,
                chipsAmountSell: 100,
            },
        }
    },
    methods: {
        async sellChips() {
            this.step = 2;
            const player = await this.$store.dispatch('wallet/sellChips', {
                pin: this.$root.pin,
                amount: this.chipStore.chipsAmountSell,
            });

            if (player) {
                eventBus.emit('update:balance', player);
                this.step = 3;
                setTimeout(() => {
                    this.step = 1;
                    this.show = false;
                    this.notify('bottom', 'right', 'Exchange Process Success! +' + (this.chipStore.chipsAmountSell - 10).toFixed(0) + ' STH')
                }, 3000)
            } else {
                this.notify('bottom', 'right', 'error: no sig or chips', 'danger');
            }

        },
    },
    computed: {
        player() {
            return this.$store.getters['account/player'];
        },
        chipsReal() {
            return this.$store.getters['account/player'].chips.real;
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    async created() {
        eventBus.on('modal:sellchips', async () => {
            this.chipStore.chipsAmountSell = 100;
            if (this.chipsReal < 100) {
                this.chipStore.chipsAmountSell = this.chipsReal;
            }
            this.step = 1;
            if (!this.$root.pin) {
                eventBus.emit('modal:unlock');
            } else {
                this.show = true;
            }
        });
    }
}
</script>

<style scoped>

</style>
