<template>
  <modal
    :show.sync="show"
    class="modal-black"
    headerClasses="justify-content-center"
  >
    <h5 class="title title-up font-weight-light text-left" slot="header">PLAYER {{$t('APP.SETTINGS')}}</h5>

    <div class="row">
      <div class="col-md-4 col-sm-3 col-xs-3">
        <img src="@/game/assets/images/avatars/alien.png" v-show="this.sex === 'alien'"/>
        <img src="@/game/assets/images/avatars/male.png" v-show="this.sex === 'male'"/>
        <img src="@/game/assets/images/avatars/female.png" v-show="this.sex === 'female'"/>

      </div>

      <div class="col-md-8 col-sm-9 col-xs-9">
        <table class="table table-settings w-100 mb-0 table-border-bot">
          <tbody>
          <tr>
            <td><i class="text-primary tim-icons tim-icons-lg icon-single-02"></i></td>
            <td class="text-uppercase">{{$t('PLAYER.NAME')}}</td>
            <td class="text-right">
              <base-input @input="setSettings" placeholder="name" type="text" v-model="name"></base-input>
            </td>
          </tr>
          <tr>
            <td><i class="text-primary tim-icons tim-icons-lg icon-badge"></i></td>
            <td class="text-uppercase">ID</td>
            <td class="text-right">
              {{player.nid}}
            </td>
          </tr>
          <tr @click="copyText(player.userId)">
            <td>
              <el-tooltip
                :content="this.mixVal.copied"
                :open-delay="300"
                effect="light"
                placement="top"
              >
                <i class="text-primary tim-icons tim-icons-lg icon-single-copy-04"></i>
              </el-tooltip>
            </td>
            <td class="text-uppercase">{{$t('WALLET.ADDRESS')}}</td>
            <td class="text-right">
              {{player.userId}}
            </td>
          </tr>
          <tr>
            <td><i class="text-primary tim-icons tim-icons-lg icon-world"></i></td>
            <td class="text-uppercase">{{$t('PLAYER.LANG')}}</td>
            <td class="text-right">
              <base-dropdown
                direction="up"
                menu-on-right=""
                tag="div"
                title-classes="btn btn-link font-weight-normal"
              >
              <span slot="title">
                {{$t('PLAYER.LANG_NAME')}} <i class="tim-icons icon-minimal-down pb-1 pl-1"></i>
              </span>
                <span @click="setLocale('en')" class="dropdown-item"> English</span>
                <span @click="setLocale('ru')" class="dropdown-item"> Russian</span>
              </base-dropdown>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="checkbox-radios justify-content-center pl-3">
            <base-radio v-model="sex" name="male" @input="setSex('male')" inline>{{$t('PLAYER.SEX_MALE')}}</base-radio>
            <base-radio v-model="sex" name="alien" @input="setSex('alien')" inline>{{$t('PLAYER.SEX_ALIEN')}}</base-radio>
            <base-radio v-model="sex" name="female" @input="setSex('female')" inline>{{$t('PLAYER.SEX_FEMALE')}}</base-radio>
            <!--
            <p>
            <span :class="sex === 'male' ? 'badge-info' : 'badge-dark'" @click="setSex('male')"
                  class="ml-2 badge badge-pill">{{$t('PLAYER.SEX_MALE')}}</span>
            <span :class="sex === 'alien' ? 'badge-info' : 'badge-dark'" @click="setSex('alien')"
                  class="ml-2 badge badge-pill">{{$t('PLAYER.SEX_ALIEN')}}</span>
            <span :class="sex === 'female' ? 'badge-info' : 'badge-dark'" @click="setSex('female')"
                  class="ml-2 badge badge-pill">{{$t('PLAYER.SEX_FEMALE')}}</span>
            </p>
            -->
          </div>
        </div>

      </div>

    </div>

  </modal>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {Modal, BaseAlert, BaseRadio} from 'src/components';
//import * as _ from 'lodash';

export default {
    name: "PlayerSettings",
    components: {
        BaseAlert,
        Modal,
        BaseRadio,
    },
    data() {
        return {
            show: false,
            timerName: null,
            sex: 'alien',
            name: 'guest',
            player: {
                name: '',
                nid: '',
                userId: '',
                sex: "alien",
            },
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    methods: {
        async setSex(sex) {
            this.sex = sex;
            await this.setSettings();
        },
        setLocale(locale) {
            this.$i18n.locale = locale;
            this.$store.dispatch('app/setLanguage', locale);
        },
        async setSettings() {
            clearTimeout(this.timerName);
            this.timerName = setTimeout(async () => {
                await this.$store.dispatch('account/setPlayerSettings', {
                    name: this.name,
                    sex: this.sex,
                });
                //await this.$store.commit('account/SET_PLAYER', result)
            }, 800);
        }
    },
    async created() {
        eventBus.on('modal:settings', async () => {
            //eventBus.emit('overlay:show');
            //this.player = _.cloneDeep(this.$store.getters['account/player']);
            //this.player = Object.assign({}, this.$store.getters['account/player']);
            this.player = this.$store.getters['account/player'];
            this.name = this.player.name;
            this.sex = this.player.sex;
            /*
            const storePlayer = this.$store.getters['account/player'];
            this.player = {
                userId: storePlayer.userId,
                name: storePlayer.name,
                nid: storePlayer.nid,
                sex: storePlayer.sex,
            }
             */
            this.show = true;
        })
    }
}
</script>

<style>
  .table > tbody > tr > td {
    padding-bottom: 9px !important;
  }
</style>
