<template>
  <modal
    :show.sync="show"
    class="modal-black"
    headerClasses="justify-content-center"
  >
    <div class="pointer text-center" slot="header">
      <img src="@/game/assets/images/chips/gold-2.png" width="48px"/>
      <h4 class="title title-up font-weight-light text-center text-uppercase mb-2">{{$t('GAME.GET_IN_GAME')}}
        {{(chipStore.chipsAmount * 1).toFixed(0) * 1}} {{$t('WALLET.CHIPS')}}</h4>
      <p>
        <base-checkbox class="text-left" v-model="check.autoGet" @input="setAutoGet">
          {{$t('GAME.AUTO_GET')}}
        </base-checkbox>
      </p>
    </div>

    <div class="text-center pl-5 pr-5"
         v-if="chipsReal >= min"
    >
      <p class="pb-4">
        <span @click="chipStore.chipsAmount = min" class="float-left pointer">MIN<br/>{{min}}</span>
        <span @click="chipStore.chipsAmount = max" class="float-right pointer">MAX<br/>{{max}}</span>
      </p>
      <slider
        :connect="true"
        :key="avg"
        :range="{min: min, max: max}"
        :start="avg"
        :step="1"
        class="mb-3"
        type="secondary"
        v-model="chipStore.chipsAmount"></slider>
    </div>

    <div class="row text-center">
      <div class="text-center text-success w-100">
        {{$t('WALLET.BALANCE')}} {{chipsReal}} CHIPS
      </div>
    </div>

    <template slot="footer">


      <base-button @click="getChipsInGame" :disabled="!allowGet" class="col-md-6 mt-2 text-uppercase">{{$t('APP.CONFIRM')}}</base-button>
      <base-button
        @click.native="show = false"
        class="col-md-6 mt-2 text-uppercase"
        type="danger"
      >{{$t('APP.CANCEL')}}
      </base-button>
    </template>

  </modal>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {Modal, Slider} from 'src/components';

export default {
    name: "GetInGame",
    components: {
        Modal,
        Slider,
    },
    data() {
        return {
            show: false,
            allowGet: false,
            check: {
              autoGet: false,
            },
            min: 20,
            max: 40,
            avg: 20,
            idx: 0,
            chipStore: {
                process: false,
                chipsAmount: 20,
            },
        }
    },
    methods: {
        async setAutoGet() {
            await this.$store.dispatch('games/setAutoGet', this.check.autoGet);
        },
        async getChipsInGame() {
            this.show = false;
            this.allowGet = false;
            const player = await this.$store.dispatch('wallet/getChipsInGame', {
                pin: this.$root.pin,
                amount: this.chipStore.chipsAmount,
                idx: this.idx,
            });

            if (player) {
              if (player.err) {
                this.notify('bottom', 'right', player.err, 'danger');
              }
                //this.notify('bottom', 'right', '+' + (this.chipStore.chipsAmount).toFixed(0) + ' CHIPS');
                //this.allowGet = true;
            } else {
                this.notify('bottom', 'right', 'error: no sig or chips', 'danger');
            }
        },
    },
    computed: {
        player() {
            return this.$store.getters['account/player'];
        },
        chipsReal() {
            return this.$store.getters['account/player'].chips.real;
        },
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    async created() {
        this.check.autoGet = this.$store.getters['games/autoGet'];

        eventBus.on('chips:getingame', async (data) => {
            this.min = data.min;
            this.max = data.max;
            const totalChips = this.$store.getters['account/player'].chips.real;
            const table = this.$store.getters['games/table'];
            this.idx = table.options.idx;
            if (totalChips < this.max) {
                this.max = totalChips;
            }
            this.chipStore.chipsAmount = Math.floor((this.min + this.max) / 2);
            this.avg = this.chipStore.chipsAmount;
            if (totalChips < this.min) {
                await eventBus.emit('modal:chips', this.min);
            } else {
                await this.getChipsInGame();
            }
        });

        eventBus.on('modal:getingame', async (data) => {
            this.check.autoGet = this.$store.getters['games/autoGet'];
            this.min = data.min;
            this.max = data.max;
            const totalChips = this.$store.getters['account/player'].chips.real;
            const table = this.$store.getters['games/table'];
            this.idx = table.options.idx;
            if (totalChips < this.max) {
                this.max = totalChips;
            }
            this.chipStore.chipsAmount = Math.floor((this.min + this.max) / 2);
            this.avg = this.chipStore.chipsAmount;

            if (totalChips >= this.min) {
                if (!this.$root.pin) {
                    await eventBus.emit('modal:unlock');
                } else {
                    let onShow = true;
                    this.allowGet = true;
                    for (let i=0; i < table.players.length; i++) {
                        if (table.players[i]) {
                            if (table.players[i].id === this.player.userId) {
                                if (table.players[i].stackSize >= data.min) {
                                    //onShow = false;
                                    this.allowGet = false;
                                    break;
                                }
                            }
                        }
                    }
                    this.show = onShow;
                }
            } else {
              await eventBus.emit('modal:chips', this.min);
            }
        });

        eventBus.on('hide:getingame', async () => {
            this.show = false;
        });

    }
}
</script>

<style scoped>

</style>
