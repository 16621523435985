prepareTx<template>
  <modal
    :show.sync="show"
    class="modal-black"
    headerClasses="justify-content-center"
    type="mini"
  >

    <h5 class="title title-up font-weight-light text-left" slot="header">{{$t('WALLET.SEND')}} STH </h5>



      <div v-if="!tx.id">
        <div style="height:30px;">
            <span class="badge badge-danger" v-show="!isValidAddress && send.address"><i
              class="tim-icons icon-bell-55"></i> Address not valid</span>
          <span class="badge badge-success" v-show="isValidAddress || !send.address">{{$t('WALLET.FEE')}} 1 STH</span>
        </div>
        <div class="row">
          <div class="col-md-12 pr-1">
            <base-input
              :class="modalClasses.address"
              @input="validateAddress"
              addon-left-icon="tim-icons icon-single-02"
              placeholder="Enter Recipient Address"
              type="text"
              v-model="send.address"
            >
            </base-input>
          </div>


          <div class="col-md-12 pr-1">
            <base-input
              :class="modalClasses.amount"
              addon-left-icon="tim-icons icon-coins"
              placeholder="Enter Amount STH"
              type="text"
              v-model="send.amount"
            >
            </base-input>
            <base-input
              addon-left-icon="tim-icons icon-paper"
              maxlength="64"
              placeholder="Memo Optional"
              type="text"
              v-model="send.memo"
            >
            </base-input>
          </div>
        </div>
        <span>{{$t("WALLET.REMAINING")}}
        <span @click="send.amount = balance" class="pointer font-weight-bolder">
          {{remainingBalance}}
        </span> STH
      </span>
      </div>


    <div v-if="tx.id" class="">
      <hr class="bg-success">
      <table class="w-100">
        <tbody>
        <tr class="border-bottom">
          <th>TX ID</th>
          <td class="text-left">{{tx.id.substr(0,8)}}..{{tx.id.substr(-8)}}</td>
        </tr>
        <tr class="border-bottom">
          <th>From</th>
          <td class="text-left">{{account.userId}}</td>
        </tr>
        <tr class="border-bottom">
          <th>To</th>
          <td class="text-left">{{tx.recipientId}}</td>
        </tr>
        <tr class="border-bottom">
          <th>Amount</th>
          <td class="text-left pl-2">{{send.amount}} STH</td>
        </tr>
        <tr class="border-bottom">
          <th>Fee</th>
          <td class="text-left pl-2">1 STH</td>
        </tr>
        <tr class="border-bottom">
          <th>Memo</th>
          <td class="text-left">{{send.memo}}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-md-6">
        <base-button @click="prepareTx"
                     :disabled="!isValidAddress || remainingBalance < 0 || !send.amount"
                     class="w-100 text-uppercase"
                     type="info"
                     v-if="!tx.id">{{$t('APP.CONTINUE')}}
        </base-button>

        <base-button v-if="tx.id" @click="broadcastTx" type="success" link class="text-info">Send {{send.amount * 1 + 1}} STH <i
          class="tim-icons icon-check-2"></i></base-button>

      </div>

      <div class="col-md-6">
        <base-button
          @click.native="show = false"
          class="w-100 text-uppercase"
          type="danger"
        >{{$t('APP.CLOSE')}}
        </base-button>
      </div>
    </div>

  </modal>
</template>

<script>
import {Modal, BaseAlert, BaseRadio} from 'src/components';
import eventBus from '@/plugins/event-bus';

export default {
    name: "SendCoins",
    components: {
        Modal,
    },
    data() {
        return {
            show: false,
            tx: {},
            txId: null,
            isValidAddress: false,
            send: {
                address: null,
                amount: null,
                memo: null,
            },
            modalClasses: {
                address: '',
                amount: ''
            },
        }
    },
    computed: {
        balance() {
            return (this.$store.getters['account/player']['sth'])
        },
        remainingBalance() {
            let result  = (this.balance - this.send.amount * 1).toFixed(8) * 1 - 1;
            if (result < 0) {
                result = 0;
            }
            return result
        },
        account() {
            return (this.$store.getters['account/account']);
        },
    },
    watch: {
        show(value) {
            if (!value) {
                this.send.memo = null;
                this.send.amount = null;
                this.tx = {};
            }
            eventBus.emit('overlay:show');
        }
    },
    methods: {
        async broadcastTx() {
            const response = await this.$store.dispatch('wallet/broadcastTxNative', this.tx);
            if (response.success === true) {
                this.txId = response.transactionIds[0];
                this.notify('bottom', 'right', 'Success send ' + this.send.amount + ' STH', 'success');
                let player = {};
                Object.assign(player, this.$store.getters['account/player']);
                player.sth = (player.sth - this.send.amount - 1);
                await this.$store.dispatch('account/setPlayer', player);
                eventBus.emit('update:balance', player);
                this.show = false;
                setTimeout(async () => {
                    this.tx = {}
                }, 9000)
            } else {
                this.notify('top', 'center', 'Error send tx, please retry!', 'danger')
            }
        },
        async prepareTx() {
            if (!this.isValidAddress) {
                return
            }
            const secret = await this.$store.dispatch('app/decrypt', this.$root.pin);
            if (secret) {
                this.tx = await this.$store.dispatch('wallet/prepareTxNative', {
                    secret: secret,
                    secondSecret: null,
                    recipient: this.send.address,
                    amount: this.send.amount,
                    memo: this.send.memo,
                });

            } else {
                eventBus.emit('modal:unlock');
            }
            return this.tx
        },
        async validateAddress() {
            this.send.address = this.send.address.trim();
            this.isValidAddress = await this.$store.dispatch('wallet/validateAddress', this.send.address);
            if (this.isValidAddress) {
                this.modalClasses.address = 'has-success'
            } else {
                this.modalClasses.address = 'has-danger'
            }
        },
    },
    async created() {
        eventBus.on('modal:txsend', async () => {
            if (!this.$root.pin) {
                eventBus.emit('modal:unlock');
            } else {
                this.show = true;
                this.send.amount = null
            }
        });
    }
}
</script>

<style scoped>

</style>
