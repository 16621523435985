<template>
  <modal
    :show.sync="show"
    class="modal-black modal-full"
    headerClasses="justify-content-center"
    style="z-index:100;"
  >
    <h4 class="title font-weight-light text-left" slot="header">
      <span v-show="!this.$root.isMobile">STH {{$t('WALLET.WALLET')}}</span>
      <span @click="copyText(player.userId)" class="text-success">
        {{player.userId}}
      </span>

      <el-tooltip
        :content="this.mixVal.copied"
        :open-delay="300"
        effect="light"
        placement="top"
      >
        <i @click="copyText(player.userId)" class="text-primary tim-icons tim-icons-lg icon-single-copy-04"></i>
      </el-tooltip>

    </h4>

    <div class="row" v-show="!this.$root.isMobile">
      <div class="col-md-5">
        <VueQrcode :options="{size:160}" :value="account.userId" class="qr-wallet mr-4 float-left"
                   v-show="!this.$root.isMobile"/>

        <div>
          <p><img src="@/game/assets/images/chips/sth-dark.png" width="24px"/> <span class="text-success">{{numberFormat(player.sth)}}</span>
            STH</p>
          <p>
            <el-tooltip
              :content="$t('WALLET.SEND') + ' STH Coins'"
              :open-delay="300"
              effect="light"
              placement="top"
            >
              <base-button :disabled="player.sth < 2" @click="modalSend" class="text-uppercase font-weight-light"
                           type="danger"> {{$t('WALLET.SEND')}} STH
              </base-button>
            </el-tooltip>
          </p>
          <p @click="sellChips">
            <img src="@/game/assets/images/chips/gold-1.png" width="24px"/> {{numberFormat(player.chips.real, '# ##0.')}} {{$t('WALLET.CHIPS')}}
          </p>
          <p>
            <base-button @click="walletRefresh" class="btn-sm" icon round type="dark">
              <el-tooltip
                :open-delay="300"
                content="Refresh"
                effect="light"
                placement="top"
              >
                <i class="tim-icons icon-refresh-01"></i>
              </el-tooltip>
            </base-button>
            {{$t('WALLET.TRANSACTIONS')}} {{txs.count}}
          </p>

        </div>
      </div>

      <div class="col-md-7">
        <Xbts/>
      </div>
    </div>

    <div class="row" v-show="this.$root.isMobile">
      <div class="col-xs-12 w-100">
        <base-button @click="showQr" class="ml-3" icon><i class="fas fa-qrcode"></i></base-button>
        <base-button :disabled="player.sth < 2" @click="modalSend" class="ml-2" icon type="info"><i
          class="tim-icons icon-spaceship"></i></base-button>
        <base-button @click="mobileExchange" class="ml-2 text-uppercase" type="primary"><i class="tim-icons icon-refresh-02"></i> {{$t('WALLET.BUY')}} STH</base-button>

        <div v-show="mobileTab === 0" class="pl-3 w-100">
          <hr/>
          <p><img src="@/game/assets/images/chips/sth-dark.png" width="24px"/> <span class="text-success">{{numberFormat(player.sth)}}</span>
            STH</p>
          <p @click="sellChips">
            <img src="@/game/assets/images/chips/gold-1.png" width="24px"/> {{numberFormat(player.chips.real, '# ##0.')}} {{$t('WALLET.CHIPS')}}
          </p>
        </div>

      </div>


    </div>


    <div class="row" v-if="!this.$root.isMobile">
      <div class="col-md-12" style="overflow-y: auto; max-height:545px;">
        <table class="table table-settings table-striped mt-2">
          <tbody>
          <tr :key="idx" v-for="(item, idx) in txs.transactions">
            <td>
              <span @click="openLink('https://blockexplorer.smartholdem.io/#/transaction/' + item.id)" class="small">{{item.id.substr(0,8)}}..{{item.id.substr(-8)}}</span>
            </td>
            <td>
              <span class="small">{{item.time}}</span>
            </td>
            <td>
              <span class="text-warning" v-show="item.op === '-'">{{item.amount}}</span>
              <span class="text-success" v-show="item.op === '+'">{{item.amount}}</span>
            </td>
            <td>
              <span class="small">{{item.senderId}}</span>
            </td>
            <td>
              <span class="small">{{item.recipientId}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ModalQr v-show="qrShow"/>
    <ModalExchange/>

  </modal>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {Modal, BaseAlert, BaseRadio} from 'src/components';
import VueQrcode from '@/util/QRCode';
import Xbts from '@/components/Exchange/Xbts'
import ModalQr from "./ModalQr";
import ModalExchange from "./ModalExchange";

export default {
    name: "Wallet",
    components: {
        BaseAlert,
        Modal,
        BaseRadio,
        VueQrcode,
        Xbts,
        ModalQr,
        ModalExchange,
    },
    data() {
        return {
            show: false,
            timerRefresh: null,
            mobileTab: 0,
            qrShow: false,
        }
    },
    computed: {
        account() {
            return this.$store.getters['account/account'];
        },
        player() {
            return this.$store.getters['account/player'];
        },
        txs() {
            return this.$store.getters['wallet/transactions'];
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    methods: {
        async mobileExchange() {
            eventBus.emit('modal:exchange');
        },
        async showQr() {
            this.mobileTab = 0;
            this.qrShow = !this.qrShow;
            eventBus.emit('modal:qr', this.player.userId);
        },
        async sellChips() {
            this.mobileTab = 0;
            eventBus.emit('modal:sellchips');
        },
        async walletRefresh() {
            clearTimeout(this.timerRefresh);
            this.timerRefresh = setTimeout(async () => {
                await this.$store.dispatch('wallet/fetchTransactions');
            }, 6000);
        },
        async modalSend() {
            this.mobileTab = 0;
            eventBus.emit('modal:txsend')
        }
    },
    async created() {
        eventBus.on('modal:wallet', async () => {
            this.show = true;
            await this.$store.dispatch('wallet/fetchTransactions');
        });
    }
}
</script>

<style>
  .modal-full .modal-content {
    height: 100vh;
  }

  .modal-full.show .modal-dialog {
    transform: translate(0, 0) !important;
    max-width: 100% !important;
    margin: 0 auto;
  }

  @media (max-width: 850px) {
    .modal-full .modal-content,
    .modal.modal-black .modal-content {
      height: 100vh;
      width: 100vw;
    }

    .modal-full.show .modal-dialog,
    .modal.modal-mini.show .modal-dialog,
    .modal.show .modal-dialog {
      margin: 0 !important;
      transform: translate(0, 0) !important;
    }
  }
</style>
