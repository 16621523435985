<template>
  <div v-if="show" class="html-elements">
    <div class="row">

      <div class="col-md-12 h-100vh" style="border-right:solid 2px #0c2646;">
        <card class="card-user justify-content-center w-100 h-100vh" style="background: url('img/bgStars.png'); overflow-x: hidden; overflow-y: auto;">
          <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>

            <div class="row" v-show="step === 0">
              <div class="col-lg-4 col-md-4 ml-auto mr-auto mt-3">
                <h3>{{$t('APP.PIN')}}</h3>
                <base-input :placeholder="$t('GAME.SET_PIN')" addon-left-icon="tim-icons icon-lock-circle"
                            class="border-0"
                            name="pin"
                            v-model="code.pin"
                            @input="numOnly"
                />
                <base-input :placeholder="$t('GAME.REPEAT_PIN')" addon-left-icon="tim-icons icon-lock-circle"
                            class="border-0"
                            name="pinR"
                            v-model="code.pinR"/>

                <p class="text-left small">* {{$t('APP.NUM_ONLY')}}</p>

                <base-button type="success" @click="setPin" :disabled="code.pin !== code.pinR || code.pin.length < 4 || code.pin.length > 15" class="w-100 text-uppercase font-weight-normal">
                  {{$t('APP.CONTINUE')}} <i class="tim-icons icon-double-right pb-1"></i>
                </base-button>

              </div>
            </div>

            <!-- player account -->
            <div v-show="step === 1" class="row">
              <div class="col-lg-8 col-md-12 col-xs-12 ml-auto mr-auto">
                <h3 class="text-center w-100">{{$t('PLAYER.PLAYER')}}</h3>

                <div class="row">
                  <div class="col-md-6 col-xs-6">
                    <base-button @click="tab = 0" class="text-uppercase font-weight-normal w-100" :type="tab === 0 ? 'info' : 'default'">{{$t('APP.GET_NEW_ACC')}} <i class="tim-icons icon-user-run pb-1 flipX"></i></base-button>
                  </div>
                  <div class="col-md-6 col-xs-6">
                    <base-button @click="tab = 1" class="text-uppercase font-weight-normal w-100" :type="tab === 1 ? 'info' : 'default'"><i class="tim-icons icon-user-run pb-1"></i> {{$t('APP.IMPORT_ACCOUNT')}}</base-button>
                  </div>
                </div>

                <!-- create account -->
                <div v-show="tab === 0" class="card-description">
                  <div class="ml-auto mr-auto">
                    <div class="row">
                    <div class="col-md-11 col-xs-11">
                      <base-input :placeholder="$t('APP.ENTER_NAME')" addon-left-icon="tim-icons icon-single-02"
                                  class="border-0"
                                  v-model="settings.name"/>
                    </div>
                      <div class="col-md-1  col-xs-1 text-right">
                        <el-tooltip
                          :content="$t('WALLET.NEW_ADDRESS')"
                          :open-delay="300"
                          effect="light"
                          placement="top"
                        >
                        <base-button @click="generateAccount" icon type="info"><i class="fas fa-dice"></i></base-button>
                        </el-tooltip>

                      </div>
                    </div>

                    <div class="text-left w-100 mt-1">
                        <table class="table table-borderless">
                          <tbody>
                          <tr>
                            <td>
                              <el-tooltip
                                :content="$t('WALLET.YOUR_PUB')"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                        <span>
                          <i class="tim-icons icon-wallet-43 text-info"></i>
                        </span>
                              </el-tooltip>
                            </td>
                            <td>
                              <el-tooltip
                                :content="$t('WALLET.YOUR_PUB')"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                              <span @click="copyText(accountGenerate.userId)" class="text-info">{{accountGenerate.userId}}</span>
                              </el-tooltip>
                            </td>
                            <td class=" text-right">
                              <el-tooltip
                                :content="this.mixVal.copied"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                                <i @click="copyText(accountGenerate.userId)" class="tim-icons icon-single-copy-04"></i>
                              </el-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <el-tooltip
                                :content="$t('WALLET.YOUR_SECRET')"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                                <span><i class="tim-icons icon-key-25 text-danger"></i></span>
                              </el-tooltip>
                            </td>
                            <td>
                              <el-tooltip
                                :content="$t('WALLET.YOUR_SECRET')"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                              <span @click="copyText(accountGenerate.secret)" class="text-danger">{{accountGenerate.secret}}</span>
                              </el-tooltip>
                            </td>
                            <td class="text-right">
                              <el-tooltip
                                :content="this.mixVal.copied"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                                <i @click="copyText(accountGenerate.secret)" class="tim-icons icon-single-copy-04"></i>
                              </el-tooltip>
                            </td>
                          </tr>
                          <tr v-show="accountGenerate.gameChain.userId">
                            <td>GAMECHAIN</td>
                            <td>{{accountGenerate.gameChain.userId}}</td>
                            <td class="text-right">
                              <el-tooltip
                                :content="this.mixVal.copied"
                                :open-delay="300"
                                effect="light"
                                placement="top"
                              >
                                <i @click="copyText(accountGenerate.gameChain.userId)" class="tim-icons icon-single-copy-04"></i>
                              </el-tooltip>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      <div class="form-check mt-1">
                        <base-checkbox class="text-left" v-model="check.lose">
                          {{$t('APP.I_SAVED')}}.<br/>{{$t('APP.IF_LOSE')}}
                        </base-checkbox>
                      </div>

                      <div class="text-center pt-2 ml-auto mr-auto">
                        <div class="row">
                          <div class="col-md-6">
                            <base-button type="warning" @click="backup"  class="w-100 text-uppercase font-weight-normal">
                              <i class="tim-icons icon-cloud-download-93 pb-1"></i> СКАЧАТЬ КЛЮЧ
                            </base-button>
                          </div>
                          <div class="col-md-6">
                            <base-button type="success" @click="setSettings" :disabled="!this.check.lose || (this.settings.name.length  < 3 || this.settings.name.length > 15)" class="w-100 text-uppercase font-weight-normal">
                              {{$t('APP.CONTINUE')}} <i class="tim-icons icon-double-right pb-1"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>


                <!-- import account -->
                <div v-show="tab === 1" class="card-description">
                  <div class="ml-auto mr-auto">
                    <base-input :placeholder="$t('APP.ENTER_NAME')" addon-left-icon="tim-icons icon-single-02"
                                class="border-0"
                                v-model="settings.name"/>
                    <base-input :placeholder="$t('APP.ENTER_SECRET')" @input="validateImportAccount()"
                                addon-left-icon="tim-icons icon-key-25"
                                class="border-0" v-model="accountImport.secret"/>

                    <div class="text-left w-100 mt-0">
                      <table class="table table-borderless" v-show="accountImport.userId">
                        <tbody>
                        <tr>
                          <td>{{$t('WALLET.ADDRESS')}} STH</td>
                          <td><span class="text-success">{{accountImport.userId}}</span></td>
                        </tr>
                        <tr v-show="balance > 0">
                          <td>{{$t('WALLET.BALANCE')}}</td>
                          <td>{{numberFormat(balance, '# ##0.')}} STH</td>
                        </tr>
                        <tr v-show="accountImport.gameChain.userId">
                          <td>GAMECHAIN</td>
                          <td>{{accountImport.gameChain.userId}}</td>
                        </tr>
                        </tbody>
                      </table>

                      <div class="row">
                        <div class="col-md-6">
                        <base-button type="success" @click="importAccount" :disabled="!this.accountImport.userId" class="w-100 text-uppercase font-weight-normal">
                          {{$t('APP.CONTINUE')}} <i class="tim-icons icon-double-right pb-1"></i>
                        </base-button>
                        </div>
                      </div>



                      <base-alert class="mb-1 small p-2" type="info" v-show="accountImport.isBip39 === false">
                        <i class="tim-icons icon-bell-55"></i> {{$t('APP.NO_BIP39')}}
                      </base-alert>
                    </div>

                  </div>
                </div>

              </div>




            </div>

          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {BaseAlert} from 'src/components';

export default {
    name: "Register",
    components: {
        BaseAlert,
    },
    data() {
        return {
            code: {
              pin: '',
              pinR: '',
            },
            step: 0,
            tab: 0,
            show: true,
            timerName: null,
            balance: 0,
            player: {
                name: '',
                nid: '',
                userId: '',
                sex: "alien",
            },
            check: {
                lose: false,
                save: false,
            },
            validateTimer: null,
            settings: {
                name: '',
                sound: true,
            },
            accountImport: {
                userId: null,
                secret: '',
                pubKey: null,
                wif: null,
                isBip39: null,
                gameChain: {
                    userId: null,
                    pubKey: null,
                },
            },
            accountGenerate: {
                userId: null,
                secret: '',
                pubKey: null,
                wif: null,
                gameChain: {
                    userId: null,
                    pubKey: null,
                },
            },
        }
    },
    watch: {
        show(value) {
            if (value) {
                eventBus.emit('overlay:show');
            } else {
                eventBus.emit('overlay:hide');
            }
        }
    },
    methods: {
        async numOnly() {
            this.code.pin = this.code.pin.replace(/\D+/g,"");
            this.code.pinR = this.code.pinR.replace(/\D+/g,"");
        },
        async accountSave() {
            this.$root.account.pin = this.code.pin;
            await this.$store.dispatch('account/accountSave', this.$root.account);
            this.$root.account = null;
        },
        async setPin() {
            await this.$store.dispatch('app/setPinEnc', this.code.pin);
            this.step = 1;
        },
        async backup() {
            let element = document.createElement('a');
            let text = 'Backup SmartPoker Account' + '\r\n';
            text = text + 'Name:' + this.settings.name + '\r\n';
            text = text + 'Address:' + this.accountGenerate.userId + '\r\n';
            text = text + 'Secret:' + this.accountGenerate.secret + '\r\n';
            text = text + 'GameChain:' + this.accountGenerate.gameChain.userId + '\r\n';
            const url = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
            element.setAttribute('href', url);
            element.setAttribute('download', this.accountGenerate.userId + '-SmartPoker-Backup.txt');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        async setSettings() {
            if (this.settings.name.length > 2 || (this.settings.name.length < 16 && this.settings.name.length > 2)) {
                if (this.check.lose) {
                    this.$root.account = this.accountGenerate;
                    await this.$store.dispatch('app/setSettings', this.settings);
                    await this.accountSave();
                    this.show = false;
                }
            }
        },
        async importAccount() {
            this.$root.account = this.accountImport;
            this.settings = {
                name: this.settings.name || this.accountImport.userId.substr(0, 12),
            };
            await this.$store.dispatch('app/setSettings', this.settings);
            await this.accountSave();
            this.show = false;
        },
        async validateImportAccount() {
            if (this.accountImport.secret.length > 7) {
                clearTimeout(this.validateTimer);
                this.validateTimer = setTimeout(async () => {
                    //this.accountImport.secret = this.accountImport.secret.trim();
                    this.accountImport = await this.$store.dispatch('account/accountImport', this.accountImport.secret);
                    this.balance = await this.$store.dispatch('account/getSthBalance', this.accountImport.userId);
                }, this.delay)
            } else {
                this.accountImport.userId = null;
                this.accountImport.isBip39 = null;
            }
        },
        async initRegister() {
            this.check.lose = false;
            this.player = {
                name: '',
                nid: '',
                userId: '',
                sex: "alien",
            };

            this.settings = {
                name: '',
                sound: true,
            };

            this.accountImport = {
                userId: null,
                secret: '',
                pubKey: null,
                wif: null,
                isBip39: null,
                gameChain: {
                    userId: null,
                    pubKey: null,
                },
            };

            await this.generateAccount();

        },
        async generateAccount() {
            this.accountGenerate = await this.$store.dispatch('account/accountGenerate');
            this.$root.account = this.accountGenerate;
        },
    },
    mounted() {
        eventBus.on('account:save', async (dataPin) => {
            this.$root.account.pin = dataPin;
            await this.$store.dispatch('account/accountSave', this.$root.account);
            this.$root.account = null;
        });

        eventBus.on('modal:onReset', async () => {
            await this.initRegister;
            eventBus.emit('scene:open', 'PanelSetPin');
            this.show = true;
            eventBus.emit('overlay:show');
        });

        eventBus.on('modal:register', async () => {
            eventBus.emit('overlay:show');
            this.show = true;
            await this.initRegister;
            this.accountGenerate = await this.$store.dispatch('account/accountGenerate');
            this.$root.account = this.accountGenerate;
        })
    },
    async created() {
        await this.generateAccount();
    }
}
</script>

<style>
  .html-elements {
    overflow: hidden;
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #05050a !important;
  }

  .border-5 {
    border: solid #0b1f38 5px !important;
  }

  .border-10 {
    border: solid #0b1f38 10px !important;
  }

  .btn-default {

  }
</style>
