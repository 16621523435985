<template>
  <modal
    :show.sync="show"
    class="modal-black"
    :show-close="false"
    headerClasses="justify-content-center"
    type="mini"
  >
    <div slot="header" class="modal-profile modal-profile-danger">
      <i class="tim-icons icon-lock-circle"></i>
    </div>
    <p class="text-uppercase">{{$t("WALLET.UNLOCK")}}</p>
    <base-input
      type="text"
      name="pin"
      placeholder="Enter Pin Code"
      addon-left-icon="tim-icons icon-key-25"
      v-model="pin"
      @input="validatePin"
    >
    </base-input>
    <template slot="footer">

    </template>
  </modal>
</template>

<script>
import {Modal} from '@/components';
import CryptoJS from 'crypto-js';
import eventBus from '@/plugins/event-bus'
export default {
    name: "Unlock",
    components: {
        Modal
    },
    data() {
        return {
            show: false,
            pin: '',
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    methods: {
        async validatePin() {
            if (this.pin.length > 3 && this.$store.getters['app/pinAES']) {
                const currentHashPin = (CryptoJS.SHA384(this.pin.toString())).toString();
                const decryptCompare = (CryptoJS.AES.decrypt(this.$store.getters['app/pinAES'], currentHashPin)).toString(CryptoJS.enc.Utf8);
                if (decryptCompare && decryptCompare === currentHashPin) {
                    this.$root.pin = currentHashPin;
                    setTimeout(() => {
                        this.pin = '';
                        this.show = false;
                        this.notify('bottom', 'center', this.$t('WALLET.REPEAT_OP'))
                    }, 500)
                }
            }
        },
    },
    async created() {
        eventBus.on('modal:unlock', async () => {
            this.pin = '';
            this.show = true;
        });
    }
}
</script>

<style scoped>

</style>
