<template>
    <div>
      <router-link to="game">Game</router-link>
    </div>
</template>

<script>
export default {
    name: "Wallet"
}
</script>

<style scoped>

</style>
