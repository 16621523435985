<template>
  <modal
    :show.sync="show"
    class="modal-black"
    :show-close="true"
    headerClasses="justify-content-center"
    type="mini"
  >
    <h4 class="title font-weight-light text-center" slot="header">
      {{$t('WALLET.BUY')}} STH
    </h4>
    <div class="text-center w-100">
      <Xbts/>
    </div>

    <template slot="footer">

    </template>
  </modal>
</template>

<script>
import Xbts from '@/components/Exchange/Xbts';
import {Modal} from 'src/components';
import eventBus from '@/plugins/event-bus';

export default {
    name: "ModalExchange",
    components: {
        Xbts,
        Modal,
    },
    data() {
        return {
            show: false,
        }
    },
    async created() {
        eventBus.on('modal:exchange', async () => {
            this.show = true;
        });
    }
}
</script>

<style scoped>

</style>
