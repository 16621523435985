<template>
  <modal
    :show.sync="show"
    class="modal-black"
    headerClasses="justify-content-center"
  >
    <div class="pointer text-center" slot="header">
      <h4 class="title title-up font-weight-light text-center text-uppercase">{{$t('WALLET.GET')}} CRYPTO
        {{$t('GAME.CHIPS')}}</h4>
      <p>{{$t('WALLET.BALANCE')}} {{balanceSth}} STH</p>

      <img src="@/game/assets/images/chips/sth-dark.png" width="72px"/>
      <i class="tim-icons icon-double-right ml-3 mr-3"></i>
      <img src="@/game/assets/images/chips/gold-2.png" width="70px"/>

    </div>


    <div class="text-center" v-show="balanceSth > amount">
      <p>{{$t('WALLET.YOU_GET')}} {{amount}} CRYPTO {{$t('WALLET.CHIPS')}}</p>
      <p>{{$t('WALLET.TO_PAY')}} {{amount}} STH + {{$t('WALLET.FEE')}} 1 STH</p>
    </div>

    <div class="text-center" v-show="balanceSth < amount">
      <p>{{$t('WALLET.FOR_GET_CHIPS')}}</p>
      <p>
        <el-tooltip
          :content="$t('WALLET.YOUR_PUB')"
          :open-delay="300"
          effect="light"
          placement="top"
        >
          <span @click="openWallet" class="text-info">
        {{account.userId}}
            </span>
        </el-tooltip>

        <el-tooltip
          :content="this.mixVal.copied"
          :open-delay="300"
          effect="light"
          placement="top"
        >
          <i @click="copyText(account.userId)" class="tim-icons icon-single-copy-04"></i>
        </el-tooltip>
      </p>
    </div>


    <template slot="footer">
      <base-button :disabled="balanceSth < amount" @click="sendTx" class="col-md-6 text-uppercase">{{$t('APP.CONFIRM')}}</base-button>
      <base-button
        @click.native="show = false"
        class="col-md-6 text-uppercase"
        type="danger"
      >{{$t('APP.CANCEL')}}
      </base-button>
    </template>

  </modal>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {Modal} from 'src/components';

export default {
    name: "BuyChips",
    components: {
        Modal
    },
    data() {
        return {
            show: false,
            amount: 2500,
            processSend: false,
            tx: null,
        }
    },
    watch: {
        show(value) {
            value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    computed: {
        account() {
            return this.$store.getters['account/account'];
        },
        balanceSth() {
            return this.$store.getters['account/player']['sth'];
        }
    },
    methods: {
        async openWallet() {
            this.show = false;
            eventBus.emit('modal:wallet');

        },
        async sendTx() {
            if (this.$root.pin) {
                this.tx = await this.prepareTx();
                if (this.tx) {
                    this.processSend = true;
                    await this.broadcastTx();
                    this.show = false;
                    let player = Object.assign({}, this.$store.getters['account/player']);
                    player.chips.real = (player.chips.real + this.amount).toFixed(0) * 1;
                    player.sth = (player.sth - this.amount - 1);
                    eventBus.emit('update:balance', player);
                    await this.$store.dispatch('account/setPlayer', player);
                }
            } else {
                eventBus.emit('modal:unlock')
            }
        },
        async prepareTx() {
            const secret = await this.$store.dispatch('app/decrypt', this.$root.pin);
            let tx = null;
            if (secret) {
                tx = await this.$store.dispatch('wallet/prepareTxNative', {
                    secret: secret,
                    secondSecret: null,
                    recipient: this.$store.getters['app/bank'],
                    amount: this.amount,
                });
            }
            return tx
        },
        async broadcastTx() {
            const response = await this.$store.dispatch('wallet/broadcastTxNative', this.tx);
            if (response.success === true) {
                this.txId = response.transactionIds[0];
                this.notify('bottom', 'right', 'Success send ' + this.amount + ' STH', 'success');
                setTimeout(async () => {
                    this.tx = {}
                }, 9000)
            } else {
                this.notify('top', 'center', 'Error send tx, please retry!', 'danger')
            }
        },
    },
    async created() {
        eventBus.on('modal:chips', async (amount) => {
            if (!this.$root.pin) {
                eventBus.emit('modal:unlock');
            } else {
                this.show = true;
            }
            this.amount = amount;
        });
    }

}
</script>

<style scoped>

</style>
