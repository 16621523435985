<template>
  <modal
    :show.sync="show"
    class="modal-black"
    :show-close="true"
    headerClasses="justify-content-center"
    type="mini"
  >

    <h4 class="title font-weight-light text-center" slot="header">
      QR-Code
      <br/> {{userId}}
    </h4>
    <div class="text-center">
      <VueQrcode :options="{size:200}" :value="userId" class="qr-wallet"/>
    </div>

    <template slot="footer">

    </template>
  </modal>
</template>

<script>
import {Modal} from 'src/components';
import VueQrcode from '@/util/QRCode';
import eventBus from '@/plugins/event-bus';
export default {
    name: "ModalQr",
    components: {
        Modal,
        VueQrcode,
    },
    data() {
      return {
          show: false,
          userId: '',
      }
    },
    async created() {
        eventBus.on('modal:qr', async (userId) => {
            this.show = true;
            this.userId = userId
        });
    }
}
</script>

<style scoped>

</style>
