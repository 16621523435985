<template>
  <div :id="containerId" v-if="downloaded">
    <notifications></notifications>

    <!-- Modal Register -->
    <Register v-if="show.register"/>

    <!-- Modal Player Settings -->
    <PlayerSettings v-if="account.userId"/>

    <!-- Modal Reset All -->
    <ResetAll/>

    <!-- Modal Wallet -->
    <Wallet v-if="account.userId"/>

    <!-- Modal Buy Chips -->
    <BuyChips v-if="account.userId"/>

    <!-- Modal Sell Chips -->
    <SellChips v-if="account.userId"/>


    <!-- Modal Get Chips in Game -->
    <GetInGame v-if="account.userId"/>

    <!-- Modal Send STH -->
    <SendCoins/>

    <!-- Modal Unlock -->
    <Unlock v-if="account.userId"/>

    <!-- chat -->
    <Chat/>


  </div>
  <div class="placeholder" style="font-family: electrolize; font-size: 50pt; color: #ffffff;" v-else>
    {{$t('GAME.LOADING')}} ...
  </div>
</template>


<script>
import eventBus from '@/plugins/event-bus';
import PlayerSettings from '@/components/Modals/PlayerSettings';
import ResetAll from '@/components/Modals/ResetAll';
import Wallet from '@/components/Modals/Wallet';
import Register from '@/components/Modals/Register';
import BuyChips from '@/components/Modals/BuyChips';
import SellChips from '@/components/Modals/SellChips';
import Unlock from '@/components/Modals/Unlock';
import SendCoins from '@/components/Modals/SendCoins';
import GetInGame from '@/components/Modals/GetInGame';
import Chat from '@/components/Modals/Chat';

export default {
    name: 'Game',
    components: {
        PlayerSettings,
        ResetAll,
        Wallet,
        Register,
        BuyChips,
        SellChips,
        Unlock,
        SendCoins,
        GetInGame,
        Chat,
    },
    data() {
        return {
            show: {
                register: false,
            },
            game: null,
            downloaded: false,
            gameInstance: null,
            containerId: 'game-container'
        }
    },
    computed: {
        account() {
            return this.$store.getters['account/account'];
        }
    },
    methods: {},
    async mounted() {
        /** game load **/
        const game = await import(/* webpackChunkName: "game" */ '@/game/game');
        this.downloaded = true;
        this.$nextTick(() => {
            this.gameInstance = game.launch(this.containerId)
        });
        setTimeout(async () => {
            const account = this.$store.getters['account/account'];
            if (!account.userId) {
                this.show.register = true;
            }
        }, 600);
        if (!this.account.userId) {
            //this.show.register = true;
        }

    },

    async created() {
        eventBus.on('app:notify', async (data = {type: 'success', msg: 'success'}) => {
            this.notify('bottom', 'right', data.msg, data.type)
        });

        this.$store._vm.$on('vuex-persist:ready', async () => {
            setTimeout(async () => {
                const account = this.$store.getters['account/account'];
                if (!account.userId) {
                    this.show.register = true;
                }
            }, 600)
        });


        eventBus.on('modal:register', async () => {
            this.show.register = true;
        });


    },
    destroyed() {
        this.gameInstance.destroy(false)
    }
}
</script>


<style lang="scss">
  .placeholder {
    font-size: 2rem;
    font-family: 'Courier New', Courier, monospace;
  }

  #game-container {
    //cursor: url("/cursor.png") auto;
    background-color: #0b1f38 !important;
  }
</style>
