<template>
  <Game/>
</template>


<script>
import Game from '@/components/Game'


export default {
    name: "PageGame",
    components: {
        Game,
    },
}
</script>

<style lang="scss">
  body {
    font-family: 'electrolize' !important;
    background-color: #0b1f38 !important;
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
  }

  .border-5 {
    border: solid #0b1f38 5px !important;
  }

  .border-10 {
    border: solid #0b1f38 10px !important;
  }


</style>
