<template>
  <div class="chat" v-show="show">
    <div class="chat-header">
      <span @click="tab = 0" class="float-left ml-3" :class="tab === 0 ? 'text-white': ''">LOG</span>
      <span @click="tab = 1" class="float-right mr-3" :class="tab === 1 ? 'text-white': ''">CHAT</span>
    </div>


    <div v-show="tab === 0" class="chat-log w-100 pl-1">
      <div class="chat-msg small" v-for="(msg, idx) in log" :key="idx + 'log'">
        <span class="text-primary">{{msg.name}}</span>
        <span class="text-warning ml-1">{{msg.action}}</span>
        <span v-show="msg.amount > 0" class="ml-1 text-white">{{msg.amount}}</span>
        <span v-show="msg.cards" class="ml-1 text-white">
          <br/>Cards {{msg.cards}}
        </span>
      </div>
    </div>


    <div v-show="tab === 1">
      <div class="w-100 float-left ml-1 position-relative">
        <base-input
          class="rounded float-left mt-1"
          style="width: 215px;"
          name="chatMsg"
          placeholder="enter message.."
          type="text"
          v-model="userMessage"
          v-on:keyup.enter="sendUserMsg"
        >
        </base-input>
        <button @click="sendUserMsg" class="btn btn-icon btn-fab btn-dark float-right mr-3"><i
          class="tim-icons icon-double-right"></i></button>
      </div>
      <div class="chat-messages-user w-100 pl-1 pr-1 mt-2">
        <div class="chat-msg small text-white" v-for="(msg, idx) in messages" :key="idx + 'm'">
          <span class="text-info" :class="player.name !==msg.name ? 'text-warning' : 'ext-info'">
            <span @click="msgAddName(msg.name)">{{msg.name}}</span>
            <span class="small text-white-50">&nbsp;{{msg.time}}</span>
          </span>
          <br/><span class="ml-1">{{msg.message}}</span>
        </div>
      </div>
    </div>

    <button class="btn btn-round btn-icon btn-fab btn-dark position-absolute bottom-0 right-0" @click="chatClose">
      <i class="tim-icons icon-simple-remove"></i></button>

  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';

export default {
    name: "Chat",
    data() {
        return {
            show: false,
            tab: 1,
            userMessage: '',
            latestMsg: '',
            latestMsgLog: '',
            messages: [],
            log: [],
        }
    },
    computed: {
        player() {
            return this.$store.getters['account/player'];
        },
        table() {
            return this.$store.getters['games/table'];
        }
    },
    methods: {
      async msgAddName(name) {
        if (this.player.name !== name) {
          this.userMessage = '' + name + ' '
        }
      },
        async chatClose() {
            this.show = false;
        },
        async sendUserMsg() {
            if (this.userMessage.length > 1 && this.latestMsg !== this.userMessage) {
                this.latestMsg = this.userMessage;
                eventBus.emit('chat:msg', {
                    op: 'chat-msg',
                    data: {
                        name: this.player.name,
                        userId: this.player.userId,
                        tid: this.table.tid,
                        message: this.userMessage.substr(0, 256),
                    }
                });
                this.userMessage = '';
            }
        },
    },
    async created() {
        eventBus.on('chat-msg:response', async (data) => {
            this.messages.unshift({
              name: data.name || null,
              message: data.message,
              time: new Date().toLocaleTimeString(),
            });


            if (this.messages.length > 500) {
                //this.messages = this.messages.splice(500, 100);
                this.messages.pop(); // удаление последнего элемента массива
            }
        });

        eventBus.on('chat-log:response', async (data) => {
            if (this.latestMsgLog !== (data.name + data.action)) {
                this.latestMsgLog = data.name + data.action;
                this.log.unshift(data);
            }
            if (this.log.length > 500) {
                //this.log = this.log.splice(500, 100);
                this.log.pop(); // удаление последнего элемента массива
            }
        });

        eventBus.on('chat:show', async () => {
            this.show = true;
        });

        eventBus.on('chat:hide', async () => {
            this.show = false;
        });
    }
}
</script>

<style>
  .chat {
    left: 0px;
    position: absolute;
    opacity: 0.96;
    bottom: 1.5%;
    width: 280px;
    min-height: 380px;
    background: #031c31;
    border-radius: 0px 20px 20px 0px;
    overflow: hidden;
    border: solid 1px #1552a6;
  }

  .chat-header {
    float: left;
    border-bottom: solid 1px #1552a6;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .chat-header span:hover {
    color: #ffffff;
    cursor: pointer;
  }

  .chat-messages-user {
    top: 82px;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
  }

  .chat-log {
    top: 40px;
    height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
  }

  .chat-msg {
    border: solid 1px #ccc;
  }

  .chat-msg {
    border: solid 1px #0f3352;
    background: #0f3352;
    margin-bottom: 3px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media only screen and (max-height: 480px) {
    .chat {
      left: 0;
      bottom: 0;
      /*width: 300px;*/
      min-height: 100vh;
      background: #031c31;
      border-radius: 0;
    }
  }
</style>
